






























import { Vue, Component } from 'vue-property-decorator';
import HeadingCard from "@/components/section/HeadingCard.vue";
import OAuthButton from '@/components/account/OAuthButton.vue';
import { Validate } from 'vuelidate-property-decorators';
import { required } from "vuelidate/lib/validators";
import { proxies } from '@/store';
import { SourceType } from '@arkive-ai/client-gateway-gql/dist/schema';
import { oauth } from '@/plugins/oauth';
import { queries, schema } from '@/gql';

@Component({
  components: {
    HeadingCard,
    OAuthButton,
  },
})
export default class CreateComunityCard extends Vue {
  private snackbar = proxies.Snackbar;
  private user = proxies.User;
  private loading = false;

  @Validate({required})
  private name = "";

  private get nameErrors() {
    const errors: string[] = [];
    if (!this.$v.name.$dirty) return errors;
    if (!this.$v.name.required) errors.push("Name is required.");
    return errors;
  }

  private async submit(sourceType: SourceType) {
    // TODO: handle different source type
    this.$v.$touch();
    if (this.loading || this.$v.$invalid) return;
    this.loading = true;

    await this.user.refreshLogin();
    const results = await oauth.openExternalBotPopup(
      SourceType.Discord,
      this.user.token,
      undefined,
      this.name,
    );
    if (results) {
      this.snackbar.showSuccess('Community is being created!');

      // short delay for new rows to be created
      await new Promise(resolve => setTimeout(resolve, 3000));

      // fetch newly created org
      try {
        const { data } = await this.$apollo.query<{ source: schema.Source }>({
          query: queries.sourceConfig,
          fetchPolicy: 'network-only', // see: https://github.com/vuejs/vue-apollo/issues/501
          variables: {uid: results.sourceUid,},
        });

        await this.$router.push({
          name: 'dashboard',
          params: {
            orgUidOrDomain: data.source.orgUid
          },
        });

      } catch (err) {
        console.error(err);

        await this.$router.push({
          name: 'communities',
        });
      }
    } else {
      this.snackbar.showWarning('Community creation cancelled');
    }
    this.loading = false;
  }
}
