


















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class ChipTooltip extends Vue {
  @Prop(String) private readonly tooltip!: string;
}
