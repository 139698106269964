


































import { Vue, Component } from 'vue-property-decorator';
import HeadingCard from "@/components/section/HeadingCard.vue";
import RoleChipTooltip from '@/components/tooltip/RoleChipTooltip.vue';
import { queries, schema } from '@/gql';
import { proxies } from '@/store';
import { mdiInformation } from '@mdi/js'

@Component({
  components: {
    HeadingCard,
    RoleChipTooltip,
  },
  apollo: {
    myOrgs: {
      fetchPolicy: 'cache-and-network',
      query: queries.myOrgs,
      update(query: schema.Query) {
        return query.me?.orgs || [];
      }
    },
  }
})
export default class CommunitiesCard extends Vue {
  private theme = proxies.Theme;
  private myOrgs: schema.Org[] = [];
  private mdiInformation = mdiInformation;

  private get loading() {
    return (this.myOrgs.length == 0) && this.$apollo.queries.myOrgs.loading;
  }
}
