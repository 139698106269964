







import { Vue, Component } from 'vue-property-decorator';
import SectionContainer from "@/components/section/SectionContainer.vue";
import CommunitiesCard from "./components/CommunitiesCard.vue";
import CreateCommunityCard from "./components/CreateCommunityCard.vue";
import { queries, schema, mutations } from '@/gql';

@Component({
  components: {
    SectionContainer,
    CommunitiesCard,
    CreateCommunityCard,
  },
  apollo: {
    me: {
      query: queries.me,
      fetchPolicy: 'cache-and-network',
    },
  },
})
export default class Communities extends Vue {
  private me!: schema.User;

  private get canCreateCommunity(): boolean {
    if (this.me?.featureFlagsJson) {
      const features = JSON.parse(this.me.featureFlagsJson)
      if (features.create_community) {
        return true
      }
    }
    return false;
  }
}
